import React from 'react'
import PropTypes from 'prop-types'
import PortableText from '@sanity/block-content-to-react';
import serializers from '../utils/serializers'
import { SanityBlock } from '../generated/graphql-types'

const RenderBlockText: React.FC<{ blocks: SanityBlock[] }> = ({ blocks }) => {
  return <PortableText
      blocks={blocks}
      serializers={serializers}
    />
}

RenderBlockText.propTypes = {
  blocks: PropTypes.array.isRequired
}

export default RenderBlockText
