import React, { ComponentProps, PropsWithChildren, useMemo } from 'react'
import _get from 'lodash/get';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading';
import Heading from 'ui/elements/Heading';
import BlockContent, { BlockContentProps } from '@sanity/block-content-to-react'

import loadable from '@loadable/component'

import { getDownloadLink, useGetInternalLink } from './helpers'

const Button = loadable(() => import('../../packages/ui/src/elements/Button') as Promise<{ default: React.FC<any> }>)
const Carousel = loadable(() => import('../../packages/ui/src/elements/Carousel'))
const List = loadable(() => import('../../packages/ui/src/elements/List'))
const FileDownload = loadable(() => import('../../packages/ui/src/elements/FileDownload/FileDownload'))
const CenteredText = loadable(() => import('../../packages/ui/src/elements/CenteredText/CenteredText'))

const YouTube = loadable(() => import('widgets/YouTube'))
const CTA = loadable(() => import('widgets/CTA'))
const Image = loadable(() => import('widgets/Image'))
const NumberedBoxes = loadable(() => import('widgets/NumberedBoxes'))
const NumberGroups = loadable(() => import('widgets/NumberGroups/NumberGroups'))
const ProgramTeaser = loadable(() => import('widgets/ProgramTeaser'))
const PageTeaser = loadable(() => import('widgets/PageTeaser'))
const Accordion = loadable(() => import('widgets/Accordion'))
const Link = loadable(() => import('widgets/Link'))
const Grid = loadable(() => import('widgets/Grid'))
const Infobox = loadable(() => import('widgets/Infobox'))

const Testimonials = loadable(() => import('widgets/Testimonials'))
const PardotForm = loadable(() => import('widgets/PardotForm'))

const BlockCTA = loadable(() => import('widgets/BlockCTA'))

const RenderHeading: React.FC<{
  style: string
}> = ({ style, children: _children }) => {
  const childArray = useMemo(() => {
    return React.Children.toArray(_children)
  }, [_children])

  const level = style.replace(/[^\d]/g, '');
  const hasLargeText = childArray.some((child) => {
    if (typeof child === "string") {
      return false
    }

    return child?.props?.node?.mark?._type === "largeText"
  })

  // replace zero-white-space that is created by sanity and breaks everything
  const children = React.Children.map(_children, child => {
    if (!child || typeof child !== 'string') {
      return child
    }

    child = child.replace(/[\u200B]/g, '')

    if (typeof child !== 'string' || (child.trim() !== '')) {
      return child
    }

    return null
  })

  // rendering is taken care of by largeText annotation
  if (hasLargeText) {
    return React.createElement(React.Fragment, undefined, children);
  }

  return React.createElement<any>(Heading, { level: parseInt(level) }, children);
}

const BlockRenderer: React.FC<{
  node: any
}> = (props) => {
  const { node: { style = 'normal' }, children } = props

  if (/^h\d/.test(style)) {
    return <RenderHeading style={style}>
      {children}
    </RenderHeading>
  }

  if (/^displayAsH\d/.test(style)) {
    const level = style.replace(/[^\d]/g, '');
    return React.createElement<any>(OpticalHeading, { level: parseInt(level) }, children);
  }

  if (style === 'blockquote') {
    return <blockquote>- {children}</blockquote>
  }

  if (style === 'small') {
    return <small>{children}</small>
  }

  return BlockContent.defaultSerializers.types.block(props)
}

const serializers: BlockContentProps['serializers'] = {
  list: (props: any) => {
    if (props.type === 'bullet') {
      return <List>{props.children}</List>
    }

    return BlockContent.defaultSerializers.list(props);
  },
  marks: {
    emailLink: ({ mark, children }) => {
      return (
        <Button href={`mailto:${mark.href}`} variant='text' color="shade">
          {children}
        </Button>
      );
    },

    fileDownload: ({ mark, children }) => {
      const file = _get(mark, 'reference.file');
      const link = getDownloadLink(file);

      if (!mark.isBlock) {
        return (
          <Button href={link} variant='text' color="shade">
            {children}
          </Button>
        )
      }

      return (
        <FileDownload link={link} size={file.asset.size} type={file.asset.extension}>
          {children}
        </FileDownload>
      );
    },

    internalLink: ({ mark, children }) => {
      const to = useGetInternalLink(mark.reference);

      return (
        <Button as={Link} to={to} variant='text' color="shade">
          {children}
        </Button>
      )
    },

    link: ({ mark, children }) => {
      return (
        <Button
          as='a'
          rel='noopener noreferrer'
          target={mark.blank ? '_blank' : '_self'}
          href={mark.href}
          variant={mark.button ? 'contained' : 'text'}
          color="shade"
        >
          {children}
        </Button>
      )
    },

    largeText: ({ mark, children }) => {
      return <OpticalHeading
        level={parseInt(mark.style_as) as ComponentProps<typeof OpticalHeading>['level']}
        renderAs={mark.render_as ?? 'span'}
        color={mark.palette}
      >
        {children}
      </OpticalHeading>
    },

    center: ({ children }) => {
      return <CenteredText>
        {children}
      </CenteredText>
    }
  },
  types: {
    form: ({ node }) => {
      return <PardotForm link={node.link} />
    },
    youtube: ({ node }) => {
      if (node.hidden) {
        return null
      }

      return <YouTube url={node.url} />;
    },

    infobox: ({ node }) => {
      if (node.hidden) {
        return null
      }

      return <Infobox node={node} />;
    },

    image: ({ node }) => {
      if (node.hidden) {
        return null
      }

      return (
        <Image
          layout="constrained"
          {...node}
        />
      );
    },

    numberedBoxes: ({ node }) => {
      if (node.hidden) {
        return null
      }

      return <NumberedBoxes node={node} />;
    },

    numberGroups: ({ node }) => {
      if (node.hidden) {
        return null
      }

      return <NumberGroups groups={node.list} perRow={node.perRow} />
    },

    reference: (props) => {
      return null;
    },
    cta: ({ node }) => {
      return <CTA node={node} />;
    },

    program: ({ node }) => {
      return <ProgramTeaser program={node} />;
    },

    route: ({ node }) => {
      return <PageTeaser page={node} />
    },

    pageTeaser: ({ node }) => {
      return <PageTeaser page={node} />
    },

    testimonialCarousel: ({ node }) => {
      if (node.hidden) {
        return null
      }

      return (
        <Testimonials testimonials={node.testimonials} variant={node.variant} />
      )
    },

    carousel: ({ node }) => {
      if (node.hidden) {
        return null
      }

      return (
        <Carousel responsive={{
          lg: { items: _get(node, 'config.itemsToShow') },
          xl: { items: _get(node, 'config.itemsToShow') },
        }}>
          {
            node.items.map((item: any) => (
              <Image
                key={item._key}
                {...item}
              />
            ))
          }
        </Carousel>
      );
    },

    grid: ({ node }) => {
      if (node.hidden) {
        return null
      }

      return (
        <Grid node={node} />
      )
    },

    accordeon: ({ node }) => {
      if (node.hidden) {
        return null
      }

      return (
        <Accordion
          variant={node.variant}
          items={node.items}
          title={node.title}
        />
      )
    },

    block: BlockRenderer,

    blockCta: ({ node }) => {
      return <BlockCTA content={node.content} color={node.color} />
    }
  }
}

export default serializers
