import { useTranslation } from 'gatsby-plugin-react-i18next';

export const localize = (value, lang = 'en') => {
  if (Array.isArray(value)) {
    return value.map(v => localize(v, lang))
  } else if (typeof value == 'object' && value !== null) {
    if (/^locale[A-Z]/.test(value._type)) {
      return localize(value[lang], lang);
    }

    return Object.keys(value).reduce((result, key) => {
      result[key] = localize(value[key], lang)

      return result;
    }, {});
  }

  return value;
};

export const useLocalize = (data) => {
  const { i18n } = useTranslation();

  return localize(data, i18n.language);
};
