import styled from 'styled-components';

import { Widget } from '../styles';
import { Heading } from 'ui/elements/Heading/styles'

export const ContentBox = styled.div`
  & + & {
    margin-top: 15px;
  }


  > div > * {
    margin: 1.5rem 0 0;

    &${Widget} {
      margin-top: 4rem;
      margin-bottom: 4rem;
    }

    &${Heading} + ${Widget} {
      margin-top: 1.5rem;
    }

    &${Widget} + ${Heading} {
      margin-top: 1.5rem;
    }
    
    &:first-child {
      margin: 0;
    }
  }

  // p {
  //   a {
  //     color: ${(props) => props.theme.palette.primary};
  //   }
  // }
`;
