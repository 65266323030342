import React from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';

import * as S from './styles';
import RenderBlockText from '../RenderBlockText'

const ContentBox = ({
  blocks,
  className,
  truncateLines,
  ellipsis,
}) => {
  if (!blocks) return null;

  const Text = <RenderBlockText blocks={blocks} />;

  if (truncateLines) {
    return (
      <Truncate
        lines={truncateLines}
        ellipsis={ellipsis}
      >
        {Text}
      </Truncate>
    )
  }

  return (
    <S.ContentBox className={className}>
      {Text}
    </S.ContentBox>
  );
};

ContentBox.propTypes = {
  truncateLines: PropTypes.number,
  className: PropTypes.string,
}

ContentBox.defaultProps = {
  ellipsis: '...',
};

export default ContentBox;
